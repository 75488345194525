<template>
 <b-sidebar v-model="show" @change="closeSider" right shadow backdrop bg-variant="white" header-class="container-title-sider" width="400px">
  <template #header>
    <div>
      <span class="title-sider">{{ $t('creator.titleSiderFilterPitches') }}</span>
      <feather-icon class="close-sider-network" icon="XIcon" size="20" @click="show = false" />
    </div>
    </template>
    <div class="mt-2 px-2 mb-2">
      <div>
        <b-form-group class="text-muted" :label="$t('creator.statusFilter')">
          <b-form-checkbox 
            v-for="(checkbox, index) in $t('creator.status_pitch')"
            :key="index"
            class="mb-05" 
            v-model="statuses"
            :value="checkbox.value"
            v-show="index < $t('creator.status_pitch').length - 1"
        >{{checkbox.text}}</b-form-checkbox>
        </b-form-group>
        <span class="d-block mb-1 text-muted">{{ $t('creator.compensation') }}</span>
        <div class="d-flex mb-2">
          <b-form-group :label="$t('creator.minAmount')">
          <cleave :options="options_cleave.number" v-model="amount_min" class="form-control"/>
          </b-form-group>
          <b-form-group :label="$t('creator.maxAmount')" class="ml-1">
          <cleave :options="options_cleave.number" v-model="amount_max" class="form-control"/>
          </b-form-group>
        </div>

        <b-form-group class="text-muted" :label="$t('creator.dueDate')">
         <div class="fields">
          <b-form-datepicker v-model="submitted_min" class="size-inputs" :placeholder="$t('creator.startDate')"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" locale="es" />
          <b-form-datepicker v-model="submitted_max" class="size-inputs" :placeholder="$t('creator.endDate')"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" locale="es" />
         </div>
        </b-form-group>
      </div>
    </div>
    <template #footer>
      <div class="button-spacing">
        <b-button class="btn-clear" variant="outline-secondary" @click="resetForm()">
          <b-img src="@/assets/images/icons/filterx.svg" class="img-filters"/>
        </b-button>
        <b-button class="size-button" variant="primary" @click="applyFilters()">
         {{ $t('creator.updateResultFilters') }}
        </b-button>
      </div>
    </template>
 </b-sidebar>
</template>
<script>
import {
  BImg,
  BSidebar,
  BFormGroup,
  BFormCheckbox,
  BFormDatepicker,
  BButton,
} from "bootstrap-vue";
import cleave from 'vue-cleave-component';
import pitch_service from '@/services/pitch_service';
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import casting_calls from '@/services/casting_calls';
export default {
  name: "SiderFilterPitch",
  components: {
    cleave,
    BImg,
    BSidebar,
    BFormGroup,
    BFormCheckbox,
    BFormDatepicker,
    BButton,
  },
  props: {
    show_sider_filter: {
      type: Boolean,
    },
    filters_layout: {
      type: Array,
    },
    casting_calls: {
      type: Object,
    }
  },
  data() {
    return {
      statuses: [],
      submitted_min: '',
      submitted_max: '',
      amount_min: 0,
      amount_max: 0,
      options_cleave: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
          prefix: '$ ',
          rawValueTrimPrefix: true
        },
      },
      show: this.show_sider_filter,
      filters: [
        {
          filter: 'statuses',
          value: [],
        },
        {
          filter: 'submitted_min',
          value: '',
        },
        {
          filter: 'submitted_max',
          value: '',
        },
        {
          filter: 'amount_min',
          value: 0,
        },
        {
          filter: 'amount_max',
          value: 0,
        },
      ],
    };
  },
  created() {
    if (this.filters_layout.length > 0) {
      this.statuses = this.filters_layout[0].value;
      this.submitted_min = this.filters_layout[1].value;
      this.submitted_max = this.filters_layout[2].value;
      this.amount_min = this.filters_layout[3].value;
      this.amount_max = this.filters_layout[4].value;
    }
  },
  methods: {
    resetForm() {
      this.statuses = []
      this.submitted_min = ''
      this.submitted_max = ''
      this.amount_min = 0
      this.amount_max = 0
    },
    closeSider() {
      this.$emit("close_siderbar_filter");
    },
    applyFilters () {
      const filteredData = {};
      
      // Agregar solo los filtros que tienen datos
      if (this.statuses !== []) filteredData.statuses = this.filters[0].value = this.statuses;
      if (this.submitted_min !== '') filteredData.submitted_min = this.filters[1].value = this.submitted_min;
      if (this.submitted_max !== '') filteredData.submitted_max = this.filters[2].value = this.submitted_max;
      if (this.amount_min !== 0) filteredData.amount_min = this.filters[3].value = this.amount_min;
      if (this.amount_max !== 0) filteredData.amount_max = this.filters[4].value = this.amount_max;
      pitch_service.detailApplyPitchByUUID(this.casting_calls.uuid, filteredData)
        .then(response => {
          if (response.status === 400) {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title:  this.$t('err'),
                text: this.$t('messageAccountSettingError'),
                icon: "CoffeeIcon",
                variant: "danger",
              },
            });
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: this.$t('creator.filterApply'),
                icon: 'CoffeeIcon',
                variant: 'success',
                text:  this.$t('creator.textfilter')
              }
            })
            this.$emit("update_casting", response.results, this.filters, casting_calls.uuid);
            this.show = false
          }
        })
    },
  }
};
</script>
<style>
.img-filters {
  width: 20px;
}
.size-inputs {
  width: 45%;
}

.fields {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.content-checkbox {
  margin-bottom: 2px;
}

.button-spacing {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px; 
}

.size-button {
  width: 75%;
  height: 40px;
}

.btn-clear {
  width: 20%;
}

.btn-clear:focus {
  width: 20%;
  background: transparent !important;
}
</style>